import React, { useContext, useEffect, useState } from "react";
import CustomRadioChip from "../../../../helpers/radio-chip";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch, } from "react-hook-form";
import {
    CONTACT_STATE,
    CREATE,
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group-doc";
import {
    getApiCallDynamic,
    postApiCallDynamicWithOutReturn,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";
import { TaskTypeValidation, TaskValidation } from "../../../../constants/validation-rules";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import MultiSelectDropDown from "../../../../helpers/dropdown/multi-select-dropdown";


import SupportingDocument from "./supporting-doc";
import PreviewTask from "./preview-task";
import { DocumentDialogCommonHooks } from "../../../../helpers/modal/modal";
import CustomInputMultiLine from "../../../../helpers/custom-input-multiline";
import { TaskContext } from "../../taskContext/TaskContext";
import ESignHandler from "./E-Sign-Handler";







export default function BorrowerTaskForm({
    formId,
    loanCode,
    edit = false,
    taskFormData,
    handleClose,
    taskReload,
    taskList,  //Borrower Master Tasks
    taskReceiverDetails,
    mainAPIData,
    info
}) {

    const {
        control,
        handleSubmit,
        formState: { errors }, setValue, getValues
    } = useForm({
        defaultValues: {

        },
    });

    const [categorizedBorrowersInfo, setCategorizedBorrowersInfo] = useState({
        primaryBorrowerInfo: null,
        coBorrowerInfo: null,
    });

    useEffect(() => {
        if (mainAPIData?.borrowerInfos) {
            console.log("mainAPIData -- ", JSON.stringify(mainAPIData, null, 2));
            console.log("mainAPIData.borrowerInfos -- ", JSON.stringify(mainAPIData?.borrowerInfos, null, 2));

            const categorizeBorrowers = (data) => {
                const result = {
                    primaryBorrowerInfo: null,
                    coBorrowerInfo: null,
                };

                data.forEach(({ borrowerType, firstName, lastName, email, phone, userCode }) => {
                    const borrowerInfo = { name: `${firstName} ${lastName}`, email, phone, userCode };

                    if (borrowerType === "Primary") {
                        result.primaryBorrowerInfo = borrowerInfo;
                    } else if (borrowerType === "Co-borrower") {
                        result.coBorrowerInfo = borrowerInfo;
                    }
                });

                return result;
            };

            const categorizedData = categorizeBorrowers(mainAPIData.borrowerInfos);
            setCategorizedBorrowersInfo(categorizedData);

            console.log("primaryBorrowerInfo: ", JSON.stringify(categorizedData.primaryBorrowerInfo, null, 2));
            console.log("coBorrowerInfo: ", JSON.stringify(categorizedData.coBorrowerInfo, null, 2));
        }
    }, [mainAPIData]); // Runs when `mainAPIData` changes



    // console.log("mainAPIData -- " + JSON.stringify(mainAPIData, null, 2))
    // console.log("mainAPIData.borrowerInfos -- " + JSON.stringify(mainAPIData?.borrowerInfos, null, 2))

    // const categorizeBorrowers = (data) => {
    //     const result = {
    //         primaryBorrowerInfo: null,
    //         coBorrowerInfo: null,

    //     };

    //     data.forEach(({ borrowerType, firstName, lastName, email, phone, userCode }) => {
    //         const borrowerInfo = { name: `${firstName} ${lastName}`, email, phone, userCode };

    //         if (borrowerType === "Primary") {
    //             result.primaryBorrowerInfo = borrowerInfo;
    //         } else if (borrowerType === "Co-borrower") {
    //             result.coBorrowerInfo = borrowerInfo;
    //         }

    //     });

    //     return result;
    // };

    // const categorizedBorrowersInfo = categorizeBorrowers(mainAPIData?.borrowerInfos);
    // console.log(JSON.stringify(categorizedBorrowersInfo, null, 2));

    // console.log("primaryBorrowerInfo" + (categorizedBorrowersInfo.primaryBorrowerInfo === null) + " - " + JSON.stringify(categorizedBorrowersInfo.primaryBorrowerInfo, null, 2));
    // console.log("coBorrowerInfo" + (categorizedBorrowersInfo.coBorrowerInfo === null) + " - " + JSON.stringify(categorizedBorrowersInfo.coBorrowerInfo, null, 2));


    const [apiCallData, setApiCallData] = useState(null);
    const [envelopeId, setEnvelopeId] = useState(null)
    const [requiresCoBorrowerESign, setRequiresCoBorrowerESign] = useState(false);
    const [requiresMLOESign, setRequiresMLOESign] = useState(false);
    const [requiresBorrower, setRequiresBorrower] = useState(true);

    const redirectUrl = process.env.REACT_APP_DOCUSIGN_REDIRECT_URL;
    const redirectUrlLocal = "http://local.kramasoft.com/";

    const { taskState, updateContextBorrowerTasks, updateContextThirdPartyTasks,

        insertSupportingDoc,
        deleteSupportingDoc,
        updateSupportingDoc,
        clearSupportingDocs,

    } = useContext(TaskContext);

    const { contextSupportingDocs } = taskState;

    const taskTypeOptions = taskList.map((task) => ({
        label: task.taskTypeName,
        value: task.id,
    }));

    const [taskTypeTitle, setTaskTypeTitle] = useState(null)
    const getTaskTypeNameById = (id) => {
        const task = taskList.find((task) => task.id === id);
        return task ? task.taskTypeName : null;
    };


    const { taskType, taskReceiver } = useWatch({ control })

    useEffect(() => {

        if (taskType) {



            setTaskTypeTitle(getTaskTypeNameById(taskType))

        }
    }, [taskType]);


    //console.log(" taskType " + getTaskTypeNameById(taskType))  // E-Sign by Borrower




    const [titles, setTitles] = useState([])
    const [taskDocs, setTaskDocs] = useState([])
    const [requiredDocs, setRequiredDocs] = useState([])
    const [supportingDocs, setSupportingDocs] = useState([])
    const [selectedReqDocs, setSelectedreqDocs] = useState([])
    const [matchedReqDocs, setMatchedReqDocs] = useState(null)

    useEffect(() => {

        if (taskType) {
            const path = API.getMasterTaskDocByTaskID() + taskType

            const taskListsData = () => {


                getApiCallDynamic({

                    path


                })
                    .then((res) => {
                        if (res?.status === 200) {
                            const tasks = res?.data || [];
                            setTaskDocs(tasks);


                            setRequiredDocs(res.data)

                            const titles = res?.data
                                .filter(item => item.documentType !== undefined)
                                .map(item => item.documentType);


                            setTitles(titles);


                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            };

            taskListsData();

            handleSelectAllChange({ target: { checked: false } });


        }
    }, [taskType]);





    const formOnSubmit = async (data) => {
        console.log(" Data during Form Submit " + JSON.stringify(data, null, 2))

        const assignedUsers = [];


        // if (requiresBorrower) {
        //     assignedUsers.push({
        //         "role": "Borrower",
        //         "userId": "1001",
        //         "name": "Borrower",
        //         "email": "borrower@gmail.com",
        //         "clientUserId": "1001",
        //         "signingStatus": "Pending"
        //     });
        // }

        // if (requiresCoBorrowerESign) {
        //     assignedUsers.push({
        //         "role": "Co-Borrower",
        //         "userId": "1002",
        //         "name": "Co Borrower",
        //         "email": "coborrower@gmail.com",
        //         "clientUserId": "1002",
        //         "signingStatus": "Pending"
        //     });
        // }
        if ((categorizedBorrowersInfo.primaryBorrowerInfo !== null)) {
            assignedUsers.push({
                "role": "Borrower",
                name: categorizedBorrowersInfo.primaryBorrowerInfo.name,
                userId: categorizedBorrowersInfo.primaryBorrowerInfo.userCode,
                clientUserId: categorizedBorrowersInfo.primaryBorrowerInfo.userCode,
                email: categorizedBorrowersInfo.primaryBorrowerInfo.email
            });

        }
        if (categorizedBorrowersInfo.coBorrowerInfo !== null) {
            assignedUsers.push({
                "role": "Co-Borrower",
                name: categorizedBorrowersInfo.coBorrowerInfo.name,
                userId: categorizedBorrowersInfo.coBorrowerInfo.userCode,
                clientUserId: categorizedBorrowersInfo.coBorrowerInfo.userCode,
                email: categorizedBorrowersInfo.coBorrowerInfo.email
            });

        }

        if (requiresMLOESign) {
            assignedUsers.push({
                "role": "MLO",
                "userId": "1003",
                "name": "Mlo",
                "email": "mlo@gmail.com",
                "clientUserId": "1003",
                "signingStatus": "Pending"
            });
        }

        const docuSignData = {
            "docuSignEnvelopeId": envelopeId,  // Envelope ID from DocuSign API
            // "documentName": "Loan_Agreement.pdf",
            //  "status": "Pending",
            "redirectUrl": redirectUrlLocal,  // Redirect after signing
            "assignedUsers": assignedUsers  // ✅ Only includes users with eSign required
        };
        console.log(" DocuSignData " + JSON.stringify(docuSignData, null, 2))


        const requiredDocuments = requiredDocs.filter(doc =>
            data?.requiredDocs?.includes(doc.documentType))
            .map(({ id, storageKey, fileName, ...rest }) => ({
                ...rest,
                blankDocStorageKey: storageKey,
                blankDocFileName: fileName,
                blankDocId: id
            }));


        const apiData = {
            //  "id": 123,
            "taskCode": null,
            "taskTypeName": taskTypeTitle,
            "loanCode": null,
            "formId": formId,
            "taskType": {
                "id": data?.taskType,

            },
            "taskDescription": data?.taskDescription,
            "dueDate": data?.dueDate,
            "taskPriority": data?.taskPriority,
            "lenderCondition": data?.isLender === 'Yes' ? true : false,

            requiredDocuments,

            supportingSampleDocuments: contextSupportingDocs,

            "taskStatus": null,
            docuSignData: docuSignData,
            "docuSignEnvelopeId": envelopeId,  // Envelope ID from DocuSign API
            // "documentName": "Loan_Agreement.pdf",
            //  "status": "Pending",
            "redirectUrl": redirectUrlLocal,  // Redirect after signing
            "assignedUsers": assignedUsers  // ✅ Only includes users with eSign required
        }


        setApiCallData(apiData);

        await postApiCallDynamicWithOutReturn({
            data: apiData,
            path: API.getThirdPartyTask,
            header: edit ? UPDATE : CREATE,
            refresh: taskReload,
        });

        handleClose();
    };

    const [finalClick, setFinalClick] = useState(false);

    const [formData, setFormData] = useState({});
    const { watch } = useForm();



    const handlePreview = (data) => {
        console.log(" Data during Form Preview " + JSON.stringify(data, null, 2))
        handleSubmit((data) => {


            const assignedUsers = [];

            // if (requiresBorrower) {
            //     assignedUsers.push({
            //         "role": "Borrower",
            //         "userId": "1001",
            //         "name": "Borrower",
            //         "email": "borrower@gmail.com",
            //         "clientUserId": "1001",
            //         "signingStatus": "Pending"
            //     });
            // }

            // if (requiresCoBorrowerESign) {
            //     assignedUsers.push({
            //         "role": "Co-Borrower",
            //         "userId": "1002",
            //         "name": "Co Borrower",
            //         "email": "coborrower@gmail.com",
            //         "clientUserId": "1002",
            //         "signingStatus": "Pending"
            //     });
            // }

            if ((categorizedBorrowersInfo.primaryBorrowerInfo !== null)) {
                assignedUsers.push({
                    "role": "Borrower",
                    name: categorizedBorrowersInfo.primaryBorrowerInfo.name,
                    userId: categorizedBorrowersInfo.primaryBorrowerInfo.userCode,
                    clientUserId: categorizedBorrowersInfo.primaryBorrowerInfo.userCode,
                    email: categorizedBorrowersInfo.primaryBorrowerInfo.email
                });

            }
            if (categorizedBorrowersInfo.coBorrowerInfo !== null) {
                assignedUsers.push({
                    "role": "Co-Borrower",
                    name: categorizedBorrowersInfo.coBorrowerInfo.name,
                    userId: categorizedBorrowersInfo.coBorrowerInfo.userCode,
                    clientUserId: categorizedBorrowersInfo.coBorrowerInfo.userCode,
                    email: categorizedBorrowersInfo.coBorrowerInfo.email
                });

            }

            if (requiresMLOESign) {
                assignedUsers.push({
                    "role": "MLO",
                    "userId": "1003",
                    "name": "Mlo",
                    "email": "mlo@gmail.com",
                    "clientUserId": "1003",
                    "signingStatus": "Pending"
                });
            }

            const docuSignData = {
                "envelopeId": envelopeId,  // Envelope ID from DocuSign API
                // "documentName": "Loan_Agreement.pdf",
                //  "status": "Pending",
                "redirectUrl": redirectUrlLocal,  // Redirect after signing
                "assignedUsers": assignedUsers  // ✅ Only includes users with eSign required
            };


            const requiredDocuments = requiredDocs.filter(doc =>
                data?.requiredDocs?.includes(doc.documentType))
                .map(({ id, storageKey, fileName, ...rest }) => ({
                    ...rest,
                    blankDocStorageKey: storageKey,
                    blankDocFileName: fileName,
                    blankDocId: id
                }));


            const apiData = {
                //  "id": 123,
                "taskCode": null,
                "taskTypeName": taskTypeTitle,
                "loanCode": null,
                "formId": formId,
                "taskType": {
                    "id": data?.taskType,

                },
                "taskDescription": data?.taskDescription,
                "dueDate": data?.dueDate,
                "taskPriority": data?.taskPriority,
                "lenderCondition": data?.isLender === 'Yes' ? true : false,

                requiredDocuments,

                supportingSampleDocuments: contextSupportingDocs,

                "taskStatus": null,
                docuSignData: docuSignData,
                "docuSignEnvelopeId": envelopeId,  // Envelope ID from DocuSign API
                // "documentName": "Loan_Agreement.pdf",
                //  "status": "Pending",
                "redirectUrl": redirectUrlLocal,  // Redirect after signing
                "assignedUsers": assignedUsers  // ✅ Only includes users with eSign required

            }
            console.log("apiData" + JSON.stringify(apiData, null, 2))

            setApiCallData(apiData);



            setModalOpen(true);


            setIsPreview(true);


        })();
    };



    const handlePrepareForESign = (data) => {

        console.log(" Prepare for e  sign called ")

        handleSubmit((data) => {


            const requiredDocuments = requiredDocs.filter(doc =>
                data?.requiredDocs?.includes(doc.documentType))
                .map(({ id, storageKey, fileName, ...rest }) => ({
                    ...rest,
                    blankDocStorageKey: storageKey,
                    blankDocFileName: fileName,
                    blankDocId: id
                }));


            const docuSignData = {



            }


            const apiData = {
                //  "id": 123,
                "taskCode": null,
                "taskTypeName": taskTypeTitle,
                "loanCode": null,
                "formId": formId,
                "taskType": {
                    "id": data?.taskType,

                },
                "taskDescription": data?.taskDescription,
                "dueDate": data?.dueDate,
                "taskPriority": data?.taskPriority,
                "lenderCondition": data?.isLender === 'Yes' ? true : false,

                requiredDocuments,

                supportingSampleDocuments: contextSupportingDocs,

                "taskStatus": null,

            }


            setApiCallData(apiData);



            setModalOpen(true);


            setIsPreview(true);


        })();
    };

    const handleButtonClick = () => {
        setFinalClick(true);
    };


    const [isPreview, setIsPreview] = useState(false);
    const handleSend = () => {

        handleSubmit(formOnSubmit)();
    };
    const handleClosePreview = () => {
        setModalOpen(false);
    };
    const handleCancelPreview = () => {
        setIsPreview(false);
    };





    const [modalOpen, setModalOpen] = useState(false);




    const [selectAll, setSelectAll] = useState(false);



    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const selectedOptions = isChecked ? titles : [];
        setValue('requiredDocs', selectedOptions);
    };



    useEffect(() => {

        if (selectedReqDocs) {



            const matchedDocs = requiredDocs.filter(doc => selectedReqDocs.includes(doc.documentType));

            setMatchedReqDocs(matchedDocs)
            const supportingDocs = matchedDocs
                .filter(item => item.blankDocument === true)
                .map(({ documentType, ...rest }) => ({
                    ...rest,
                    documentName: documentType,
                }));

            clearSupportingDocs()

            if (supportingDocs && supportingDocs.length > 0) {
                supportingDocs.forEach(doc => insertSupportingDoc(doc));
            }


        }
        else if (selectedReqDocs.length !== 0) {
            clearSupportingDocs()
        }
    }, [selectedReqDocs]);


    console.log(" Does it require Co-Borrower to e-sign " + requiresCoBorrowerESign)
    console.log(" Does it require MLO to e-sign " + requiresMLOESign)

    return (
        <>







            {!isPreview ? (
                <Box >

                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="left">
                        <Grid container spacing={2} justifyContent="center">

                            <Grid item xs={12} md={12}>

                                <form onSubmit={handleSubmit(formOnSubmit)}>

                                    <Typography variant="h3" paragraph>Create New Task</Typography>



                                    <Controller
                                        name='taskType'
                                        control={control}
                                        rules={TaskTypeValidation.taskType}
                                        render={({ field }) => (
                                            <CustomDropDown
                                                {...field}
                                                field={field}
                                                label={'Task Type'}
                                                required
                                                state={taskType}
                                                option={taskTypeOptions}
                                                error={errors?.taskType?.message}
                                            />
                                        )}
                                    />
                                    <Box mt={4} mb={1}>
                                        {/* Checkbox for Co-Borrower */}
                                        <label className="flex items-center space-x-2 mb-2">
                                            <input
                                                type="checkbox"
                                                checked={requiresCoBorrowerESign}
                                                onChange={(e) => setRequiresCoBorrowerESign(e.target.checked)}
                                                className="w-4 h-4"
                                            />
                                            <span>Does it require Co-Borrower to e-sign?</span>
                                        </label>
                                    </Box>

                                    <Box mb={4} mt={1}>
                                        {/* Checkbox for MLO */}
                                        <label className="flex items-center space-x-2 mb-4">
                                            <input
                                                type="checkbox"
                                                checked={requiresMLOESign}
                                                onChange={(e) => setRequiresMLOESign(e.target.checked)}
                                                className="w-4 h-4"
                                            />
                                            <span>Does it require MLO to e-sign?</span>
                                        </label>

                                    </Box>



                                    <Controller
                                        name="taskDescription"
                                        control={control}

                                        rules={{ required: "Task description is required" }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomInputMultiLine

                                                {...field}
                                                label="Enter Task Description"
                                                multiline
                                                error={error?.message}
                                            />
                                        )}
                                    />


                                    {/* <Controller
                                name="taskDescription"
                                control={control}
                                rules={{ required: "Task description is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <CustomInputMultiLine
                                        {...field}
                                        label="Enter Task Description"
                                        multiline
                                        error={error?.message}
                                    />
                                )}
                            /> */}




                                    {getTaskTypeNameById(taskType) !== 'E-Sign by Borrower' &&
                                        <Box marginY={4}>

                                            <Typography variant="h3" paragraph>Required Document</Typography>
                                            <Stack direction="column" spacing={2}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectAll}
                                                                onChange={handleSelectAllChange}
                                                            />
                                                        }
                                                        label="Select All"
                                                    />
                                                </Box>
                                            </Stack>
                                            {/* <Controller
                                            name='requiredDocs'
                                            control={control}
                                            render={({ field }) => (
                                                <MultiSelectDropDown
                                                    field={field}

                                                    options={titles}
                                                    selectAll={selectAll}
                                                    errors={errors.requiredDocs}
                                                //label="Tag"
                                                />
                                            )}
                                        /> */}



                                            < Controller
                                                name='requiredDocs'
                                                control={control}
                                                render={({ field }) => (
                                                    <MultiSelectDropDown
                                                        field={field}
                                                        options={titles}
                                                        selectAll={selectAll}
                                                        changeCallback={(selected) => {
                                                            setSelectedreqDocs(selected);

                                                        }}
                                                        errors={errors.requiredDocs}
                                                    />
                                                )}
                                            />



                                        </Box>
                                    }






                                    <Controller
                                        name="dueDate"
                                        control={control}
                                        rules={TaskValidation.dueDate}
                                        render={({ field }) => (
                                            <CustomInput
                                                {...field}
                                                field={field}
                                                type={"date"}
                                                // maxDate={currentDate()}
                                                label={"Due date"}
                                                required
                                                error={errors?.dueDate?.message}
                                            />
                                        )}
                                    />







                                    <Controller
                                        name="taskPriority"
                                        control={control}
                                        rules={TaskValidation.taskPriority}
                                        render={({ field }) => (
                                            <CustomRadioChip
                                                {...field}
                                                field={field}
                                                required
                                                label={"Task priority"}
                                                data={TASK_PRIORITY}
                                                error={errors?.taskPriority?.message}

                                            />
                                        )}
                                    />





                                    <Controller
                                        name="isLender"
                                        control={control}
                                        rules={TaskValidation.isLender}
                                        render={({ field }) => (
                                            <RadioButtonGroup
                                                {...field}
                                                field={field}
                                                required
                                                direction="row"
                                                labelComponent={<FormLabel>{`Is Lender's Condition?`}</FormLabel>}
                                                data={TASK_LENDERS_CONDITION}
                                                error={errors?.isLender?.message}
                                            />
                                        )}
                                    />





                                    <Grid container>
                                        <Grid item xs={12} md={12} marginY={4}>
                                            <Typography variant="h3" paragraph>
                                                {getTaskTypeNameById(taskType) !== 'E-Sign by Borrower' ? 'Supporting Documents: ' : 'Upload E-Sign Documents'}
                                            </Typography>


                                            <SupportingDocument
                                                supportingDocs={contextSupportingDocs}
                                                setSupportingDocs={setSupportingDocs}
                                                formId={formId}
                                                loanCode={loanCode}
                                                documentTypes={titles}
                                            />
                                        </Grid>
                                    </Grid>

                                    {getTaskTypeNameById(taskType) === 'E-Sign by Borrower' &&
                                        (
                                            <>
                                                <Box mb={3}>
                                                    {/* <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                                                        <Button
                                                            variant="outlined"  // variant="text" variant="contained"
                                                            onClick={() => handlePrepareForESign(getValues())}
                                                        //disabled={false}
                                                        >
                                                            Prepare for E-Sign
                                                        </Button>
                                                    </Stack> */}
                                                    <ESignHandler
                                                        // data={data}
                                                        requiredDocs={requiredDocs}
                                                        contextSupportingDocs={contextSupportingDocs}
                                                        formId={formId}
                                                        taskTypeTitle={taskTypeTitle}
                                                        handleSubmit={handleSubmit}
                                                        setApiCallData={setApiCallData}
                                                        setModalOpen={setModalOpen}
                                                        setIsPreview={setIsPreview}
                                                        requiresCoBorrowerESign={requiresCoBorrowerESign}
                                                        requiresMLOESign={requiresMLOESign}
                                                        requiresBorrower={requiresBorrower}
                                                        setEnvelopeId={setEnvelopeId}
                                                        categorizedBorrowersInfo={categorizedBorrowersInfo}
                                                    />
                                                </Box>
                                            </>


                                        )
                                    }

                                    <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handlePreview(getValues())}
                                            disabled={false}
                                        >
                                            Preview
                                        </Button>
                                        <Button variant="text" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </form>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

            ) : (


                <DocumentDialogCommonHooks
                    show={modalOpen}
                    handleClose={handleClose}
                    // title={`Add Task Type`}
                    size={'lg'}
                    body={
                        <PreviewTask
                            isPreview={true}
                            formData={apiCallData}
                            handleSend={handleSend}
                            handleCancelPreview={handleCancelPreview}
                            info={info}
                            contextSupportingDocs={contextSupportingDocs}
                        />
                    }
                />



            )}



        </>
    );
}

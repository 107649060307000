
import { Box, Button, Grid, MenuItem, Typography, Select, Stack, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ConfirmationModel from "../../helpers/modal/confirm-modal";
import CustomReactMaterialTable from "../../helpers/table/react-material-table";
import CustomReactMaterialTablePagination from "../../helpers/table/react-material-table-pagination";
import DialogSuccesMsg from "../../helpers/modal/dialog-succes-msg";
import { DialogCommonHooks } from "../../helpers/modal/modal";
import { API } from "../../services/api-config";
import { getApiCallDynamic, postApiCallDynamic, postApiCallDynamicWithParams } from "../../services/api-service";
import { profileState } from "../../recoil/profile-atom";
import { useRecoilValue } from "recoil";
import { ROLE_LIST } from "../../constants/constatnt-variable";
import { KSPencilAltIcon, KSTrashIcon } from "../../icons/custome-icon";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function Merge() {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [mloOwnerList, setMloOwnerList] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [mergerDataByRequested, setMergerDataByRequested] = useState([]);
  const [mergerDataByRequesting, setMergerDataByRequesting] = useState([]);
  const [mergerDataFor, setMergerDataFOr] = useState([]);

  const [mergerDataForRequested, setMergerDataForRequested] = useState([]);
  const [mergerDataForRequesting, setMergerDataForRequesting] = useState([]);
  const [openConfirmAccept, setOpenConfirmAccept] = useState(false);
  const [openConfirmReject, setOpenConfirmReject] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);

  const [refresh, setRefresh] = useState(false)






  let profileInfo = useRecoilValue(profileState);

  useEffect(() => {
    getApiCallDynamic({ path: API.mergerMLOOwnerList })
      .then((res) => {
        console.log("Full API Response:", res);
        setMloOwnerList(res?.data || []);
      })
      .catch((error) => {
        console.error("API Fetch Error:", error);
      });

    getApiCallDynamic({ path: API.mergerRequestBy })
      .then((res) => {
        console.log("setMergerDataBy:", res);

        const transformedDataRequested = res.data.map(({ requestedUser, ...rest }) => ({
          ...rest,
          ...requestedUser,
          state: requestedUser?.stateLicenses?.[0]?.state || null,
        }));
        const transformedDataRequesting = res.data.map(({ requestingUser, ...rest }) => ({
          ...rest,
          ...requestingUser,
          state: requestingUser?.stateLicenses?.[0]?.state || null,
        }));
        transformedDataRequested.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate));  // desc
        setMergerDataByRequesting(transformedDataRequesting);
        setMergerDataByRequested(transformedDataRequested); // final
      })
      .catch((error) => {
        console.error("API Fetch Error:", error);
      });

    getApiCallDynamic({ path: API.mergerRequestFor })
      .then((res) => {
        console.log("setMergerDataBy:", res);

        const transformedDataRequested = res.data.map(({ requestedUser, ...rest }) => ({
          ...rest,
          ...requestedUser,
          state: requestedUser?.stateLicenses?.[0]?.state || null,
        }));
        const transformedDataRequesting = res.data.map(({ requestingUser, ...rest }) => ({
          ...rest,
          ...requestingUser,
          state: requestingUser?.stateLicenses?.[0]?.state || null,
        }));
        // transformedDataRequesting.sort((a, b) => new Date(a.requestDate) - new Date(b.requestDate)); // asce
        transformedDataRequesting.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate));  // desc
        transformedDataRequested.sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate));

        setMergerDataForRequesting(transformedDataRequesting);  // final
        setMergerDataForRequested(transformedDataRequested);
        console.log("setMergerDataFOr:", JSON.stringify(transformedData, null, 2));
      })
      .catch((error) => {
        console.error("API Fetch Error:", error);
      });

    setRefresh(false)






  }, [refresh]);



  const handleSuccessOpen = async () => {
    if (selectedOwner) {
      console.log("Selected Owner Details:", JSON.stringify(selectedOwner, null, 2));

      const params = {
        requestedUserCode: selectedOwner?.userCode,
        stateCode: selectedOwner?.stateLicenses[0]?.state
      }

      try {
        const res = await postApiCallDynamicWithParams({
          path: API.mergeSentRequest,
          params: params
        });


        console.log("Full API Response Object:", res);


        if (res && res.status === 200) {
          console.log("API call successful, opening success modal...");
          setSuccessModalOpen(true);
          setRefresh(true)
        } else {
          console.log("Unexpected response structure:", res);
        }

      } catch (error) {
        console.error("Error in API call:", error);
      }
    }
  };



  // const handleSuccessOpen = () => {
  //   if (selectedOwner) {
  //     console.log("Selected Owner Details:", selectedOwner);



  //     setSuccessModalOpen(true);
  //   }
  // };

  const handleClose = () => {
    setSuccessModalOpen(false);
    handleModalClose();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleSelectChange = (event) => {
    const selected = mloOwnerList.find(owner => owner.userCode === event.target.value);
    setSelectedOwner(selected);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "nmlsId",
        header: "NMLS ID",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "firstName",
        header: "Name",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "state",
        header: "State",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "requestStatus",
        header: "Merge Status",
        enableColumnActions: false,
        enableSorting: false,
        // Cell: ({ cell }) => (
        //   <Typography variant="body1" color="warning.main">
        //     {cell.getValue() === "Init" ? "Pending" : cell.getValue()}
        //   </Typography>
        // ),
        Cell: ({ cell }) => (
          <Typography
            variant="body1"
            color={
              cell.getValue() === "Accepted"
                ? "success.main"
                : cell.getValue() === "Rejected"
                  ? "error.main"
                  : "warning.main"
            }
          >
            {cell.getValue() === "Init" ? (
              <>
                <AccessTimeIcon sx={{ fontSize: 16, marginRight: 1 }} />
                Pending
              </>
            ) : (
              cell.getValue()
            )}
          </Typography>
        )
      },

      //   Cell: ({ cell }) => (
      //     <Typography variant="body1" color="warning.main">
      //       {cell.getValue() === "Init" ? (
      //         <>
      //           <AccessTimeIcon sx={{ fontSize: 16, marginRight: 1 }} />
      //           Pending
      //         </>
      //       ) : (
      //         cell.getValue()
      //       )}
      //     </Typography>
      //   )
      // },

      // {
      //   accessorKey: "requestDate",
      //   header: "Date Requested",
      //   enableColumnActions: false,
      //   enableSorting: false,
      // },
      {
        accessorKey: "requestDate",
        header: "Date Requested",
        enableColumnActions: false,
        // enableSorting: false,

        Cell: ({ cell }) => {
          const rawDate = cell.getValue();
          if (!rawDate) return "N/A";

          const userLocale = navigator.language || "en-US";
          const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          const formattedDate = new Date(rawDate).toLocaleString(userLocale, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
            timeZone: userTimeZone,
          });

          return formattedDate;
        },
      }

      //end
    ],
    []
  );
  const handleFinalMerge = async (action, recordDetails) => {
    const apiCallData = {
      id: recordDetails?.id,
      action: action
    };

    console.log(`Action = ${action}, Record Details:`, JSON.stringify(recordDetails, null, 2));

    try {
      const res = await postApiCallDynamicWithParams({
        path: API.mergeSelectedAction,
        params: apiCallData,
        method: "PUT",
        header: "application/json"
      });


      console.log("Response:", res);

      if (res?.success) {
        console.log("API call successful!", res.data);
      } else {
        console.error("API call failed:", res?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const handleAccept = (recordDetails) => {
    handleFinalMerge("Accepted", recordDetails);
    setRefresh(true)
  };

  const handleReject = (recordDetails) => {
    handleFinalMerge("Rejected", recordDetails);
    setRefresh(true)
  };



  const data = []
  return (
    <>
      {
        profileInfo.role === ROLE_LIST.broker ? (
          <>

            <Grid container direction={"row"}>
              <Grid item sm={12} md={5} my={1}>
                <Typography variant="h3" marginBottom={"8px"}>Merge</Typography>
                <Typography variant="body2">Account number to invite for the merge</Typography>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12} my={1} overflow={"auto"}>
              <CustomReactMaterialTable
                endPoint="/api/users"
                columns={columns}
                staticLoad={true}
                staticData={mergerDataByRequested}
                enableToolbarInternalActions={false}
                enablePagination={true}
                enableSorting={true}
                renderTopToolbarCustomActions={() => (
                  <Box my={1} display="flex" justifyContent="end">
                    <Button variant="outlined" onClick={handleOpenModal}>
                      Merge More
                    </Button>
                  </Box>
                )}
              />
              {/* <CustomReactMaterialTablePagination
                endPoint="/api/users"
                columns={columns}
                staticLoad={true}
                staticData={mergerDataByRequested}
                enableToolbarInternalActions={false}
                enablePagination={true}
                renderTopToolbarCustomActions={() => (
                  <Box my={1} display="flex" justifyContent="end">
                    <Button variant="outlined" onClick={handleOpenModal}>
                      Merge More
                    </Button>
                  </Box>
                )}
                options={{
                  pageSize: pageSize, 
                  pageSizeOptions: [5, 10, 25], 
                  page: currentPage, 
                  onPageChange: (newPage) => setCurrentPage(newPage), 
                  onRowsPerPageChange: (newPageSize) => setPageSize(newPageSize), 
                }}
              /> */}
            </Grid>

            {/* Merge Request Modal */}
            <DialogCommonHooks
              title="Account number to invite"
              show={openModal}
              handleClose={handleModalClose}
              body={
                <Grid container direction={"column"} my={2} spacing={2}>
                  <Grid item>
                    <Select
                      fullWidth
                      value={selectedOwner?.userCode || ""}
                      onChange={handleSelectChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select Owner</MenuItem>
                      {mloOwnerList?.map((owner) => (
                        <MenuItem key={owner.userCode} value={owner.userCode}>
                          {`${owner.nmlsId}, ${owner.firstName} ${owner.lastName}, ${owner.stateLicenses[0]?.state || "N/A"}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item textAlign={{ xs: "center", md: "right" }}>
                    <ConfirmationModel
                      disabled={!selectedOwner}
                      body={
                        <Button variant="contained">
                          Send Merge Request
                        </Button>
                      }
                      handleSubmit={handleSuccessOpen}
                      message={"Are you sure you want to send the merge request?"}
                      title={"Confirmation"}
                    />
                  </Grid>
                </Grid>
              }
            />

            {/* Merge Request Success Modal */}
            <DialogCommonHooks
              show={successModalOpen}
              handleClose={handleClose}
              body={
                <DialogSuccesMsg
                  description="Thank You! Request has been sent."
                  handleSubmit={handleSuccessOpen}
                  handleClose={handleClose}
                />
              }
            />



          </>
        )
          : profileInfo.role === ROLE_LIST.mloOwner ? (
            <>

              <Grid container direction={"row"}>
                <Grid item sm={12} md={5} my={1}>
                  <Typography variant="h3" marginBottom={"8px"}>Merge</Typography>
                  <Typography variant="body2">Merge Requests </Typography>
                </Grid>
              </Grid>

              <Grid item sm={12} md={12} my={1} overflow={"auto"}>
                <CustomReactMaterialTable
                  endPoint="/api/users"
                  columns={columns}
                  staticLoad={true}
                  staticData={mergerDataForRequesting}
                  enableToolbarInternalActions={false}
                  enablePagination={true}
                  enableTopToolbar={false}
                  // enablePagination={false}
                  // rowData={userInfo.stateLicenses.length ? userInfo.stateLicenses : rowData}
                  // columns={columns}
                  enableRowActions={true}
                  initialState={{
                    sorting: [{ id: "requestDate", desc: true }], // Default sort: Descending (latest first)
                  }}
                  // renderRowActions={({ row }) => {
                  //   return (
                  //     <Stack direction={'row'} spacing={2}> {/* Adds space between buttons */}
                  //       <IconButton
                  //         onClick={() => { setEditState(row.index) }}
                  //         sx={{
                  //           backgroundColor: 'green',
                  //           color: 'white',
                  //           borderRadius: '20px', // Rounded corners
                  //           padding: '8px 16px', // Padding for cylindrical look
                  //           fontSize: '13px', // Slightly larger font size
                  //           '&:hover': { backgroundColor: 'darkgreen' }
                  //         }}
                  //       >
                  //         Accept
                  //       </IconButton>

                  //       <ConfirmationModel
                  //         body={
                  //           <IconButton
                  //             id={row.index}
                  //             sx={{
                  //               backgroundColor: 'red',
                  //               color: 'white',
                  //               borderRadius: '20px', // Rounded corners
                  //               padding: '8px 16px', // Padding for cylindrical look
                  //               fontSize: '13px', // Slightly larger font size
                  //               '&:hover': { backgroundColor: 'darkred' }
                  //             }}
                  //           >
                  //             Reject
                  //           </IconButton>
                  //         }
                  //         message={`Are you sure want to reject ?`}
                  //         title={'Reject '}
                  //         handleSubmit={(event) => { handleCloseDelete(event, row) }}
                  //       />
                  //     </Stack>
                  //   );
                  // }}
                  // renderRowActions={({ row }) => {
                  //   return (
                  //     <Stack direction={'row'} spacing={2}>
                  //       {/* Accept Button */}
                  //       <ConfirmationModel
                  //         body={
                  //           <IconButton
                  //             sx={{
                  //               backgroundColor: 'green',
                  //               color: 'white',
                  //               borderRadius: '20px',
                  //               padding: '8px 16px',
                  //               fontSize: '13px',
                  //               '&:hover': { backgroundColor: 'darkgreen' },
                  //             }}
                  //             onClick={() => {

                  //               setOpenConfirmAccept(true);
                  //               setSelectedRecord(row.original);
                  //             }}
                  //             disabled={row.original.requestStatus === "Accepted" || row.original.requestStatus === "Rejected"}
                  //           >
                  //             Accept
                  //           </IconButton>
                  //         }
                  //         message={`Are you sure you want to accept merge request?`}
                  //         title={'Accept'}
                  //         handleSubmit={() => {

                  //           handleAccept(selectedRecord);
                  //           setOpenConfirmAccept(false);
                  //         }}
                  //       />


                  //       <ConfirmationModel
                  //         body={
                  //           <IconButton
                  //             id={row.index}
                  //             sx={{
                  //               backgroundColor: 'red',
                  //               color: 'white',
                  //               borderRadius: '20px',
                  //               padding: '8px 16px',
                  //               fontSize: '13px',
                  //               '&:hover': { backgroundColor: 'darkred' },
                  //             }}
                  //             //   onClick={() => {

                  //             //     setOpenConfirmReject(true);
                  //             //     setSelectedRecord(row.original);
                  //             //   }}
                  //             //   disabled={row.original.requestStatus === "Accepted" || row.original.requestStatus === "Rejected"}
                  //             // >
                  //             onClick={(e) => {
                  //               if (row.original.requestStatus !== "Accepted" && row.original.requestStatus !== "Rejected") {
                  //                 setOpenConfirmReject(true);
                  //                 setSelectedRecord(row.original);
                  //               }
                  //               // Optionally prevent further propagation if needed
                  //               e.preventDefault();
                  //               e.stopPropagation();
                  //             }}
                  //             disabled={row.original.requestStatus === "Accepted" || row.original.requestStatus === "Rejected"}
                  //           >
                  //             Reject
                  //           </IconButton>
                  //         }
                  //         message={`Are you sure you want to decline merge request?`}
                  //         title={'Reject'}
                  //         handleSubmit={() => {

                  //           handleReject(selectedRecord);
                  //           setOpenConfirmReject(false);
                  //         }}
                  //       />

                  //     </Stack>
                  //   );
                  // }}
                  renderRowActions={({ row }) => {
                    const isRequestAcceptedOrRejected = row.original.requestStatus === "Accepted" || row.original.requestStatus === "Rejected";


                    if (isRequestAcceptedOrRejected) {
                      return null;
                    }

                    return (
                      <Stack direction={'row'} spacing={2}>
                        {/* Accept Button */}
                        <ConfirmationModel
                          body={
                            <IconButton
                              sx={{
                                backgroundColor: 'green',
                                color: 'white',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontSize: '13px',
                                '&:hover': { backgroundColor: 'darkgreen' },
                              }}
                              onClick={() => {
                                setOpenConfirmAccept(true);
                                setSelectedRecord(row.original);
                              }}
                              disabled={isRequestAcceptedOrRejected}
                            >
                              Accept
                            </IconButton>
                          }
                          message={`Are you sure you want to accept the merge request?`}
                          title={'Accept'}
                          handleSubmit={() => {
                            handleAccept(selectedRecord);
                            setOpenConfirmAccept(false);
                          }}
                        />

                        {/* Reject Button */}
                        <ConfirmationModel
                          body={
                            <IconButton
                              id={row.index}
                              sx={{
                                backgroundColor: 'red',
                                color: 'white',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontSize: '13px',
                                '&:hover': { backgroundColor: 'darkred' },
                              }}
                              onClick={(e) => {
                                setOpenConfirmReject(true);
                                setSelectedRecord(row.original);
                              }}
                              disabled={isRequestAcceptedOrRejected}
                            >
                              Reject
                            </IconButton>
                          }
                          message={`Are you sure you want to decline the merge request?`}
                          title={'Reject'}
                          handleSubmit={() => {
                            handleReject(selectedRecord);
                            setOpenConfirmReject(false);
                          }}
                        />
                      </Stack>
                    );
                  }}


                // enableBottomToolbar={false}

                />
              </Grid>



              {/* Merge Request Success Modal */}
              <DialogCommonHooks
                show={successModalOpen}
                handleClose={handleClose}
                body={
                  <DialogSuccesMsg
                    description="Thank You! Request has been sent."
                    handleSubmit={handleSuccessOpen}
                    handleClose={handleClose}
                  />
                }
              />



            </>
          ) : null








      }
    </>)
}

export default Merge;

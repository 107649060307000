

import { Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { API } from "../../../../services/api-config";
import { documentPreview, downloadEsignDocumentBeforeResolve } from "../../../../helpers/file-upload-download";
import { postApiCallDynamic } from "../../../../services/api-service";
import { useRecoilValue } from "recoil";
import { formState, profileState } from "../../../../recoil/profile-atom";

const ESignHandler = ({
    contextSupportingDocs,
    requiresBorrower = true,
    requiresCoBorrowerESign,
    requiresMLOESign,
    setEnvelopeId,
    categorizedBorrowersInfo
}) => {

    // let profileInfo = useRecoilValue(profileState);
    // console.log("profileInfo -- " + JSON.stringify(profileInfo, null, 2))
    // let formData = useRecoilValue(formState);




    // Convert Blob to Base64
    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(",")[1]); // Extract Base64
            reader.onerror = reject;
        });
    };

    // Download and Convert document to Base64
    const previewDocument = async (row) => {
        if (!row) {
            console.error("Row data is missing");
            return null;
        }

        const storageKey = row?.storageKey;
        const fileName = row?.fileName;
        let apiUrl;
        let blobFile = null;

        try {
            if (row?.docType === "LocalUpload") {
                blobFile = await downloadEsignDocumentBeforeResolve(API.proxyDocumentDownload, {
                    id: row?.docId || row?.storageDocId,
                    serviceType: "Loan",
                });
            } else {
                apiUrl = API.downloadDoc;
                if (storageKey && fileName) {
                    blobFile = await downloadEsignDocumentBeforeResolve(apiUrl, { storageKey, fileName });
                } else {
                    console.error("Missing storageKey or fileName for document preview");
                    return null;
                }
            }

            if (blobFile) {
                const base64Data = await convertBlobToBase64(blobFile);
                console.log(`Base64 Length for ${fileName}:`, base64Data.length);
                return base64Data;
            }
        } catch (error) {
            console.error("Error processing document:", error);
            return null;
        }
    };

    // Upload and Edit Multiple Files in DocuSign
    const handleUploadAndEditMultipleFiles = async () => {
        const signers = [];
        let requiredPlaceholders = 0;
        let userId;
        // if (requiresBorrower) {
        //     signers.push({ name: "Borrower", userId: "1001", email: "borrower@gmail.com" });
        //     requiredPlaceholders++;
        // }
        // if (requiresCoBorrowerESign) {
        //     signers.push({ name: "Co Borrower", userId: "1002", email: "coborrower@gmail.com" });
        //     requiredPlaceholders++;
        // }
        // if (requiresMLOESign) {
        //     signers.push({ name: "Mlo", userId: "1003", email: "mlo@gmail.com" });
        //     requiredPlaceholders++;
        // }

        if ((categorizedBorrowersInfo.primaryBorrowerInfo !== null)) {
            signers.push({
                name: categorizedBorrowersInfo.primaryBorrowerInfo.name,
                userId: categorizedBorrowersInfo.primaryBorrowerInfo.userCode,
                email: categorizedBorrowersInfo.primaryBorrowerInfo.email
            });
            requiredPlaceholders++;
        }
        if (categorizedBorrowersInfo.coBorrowerInfo !== null) {
            signers.push({
                name: categorizedBorrowersInfo.coBorrowerInfo.name,
                userId: categorizedBorrowersInfo.coBorrowerInfo.userCode,
                email: categorizedBorrowersInfo.coBorrowerInfo.email
            });
            requiredPlaceholders++;
        }
        if (requiresMLOESign) {
            signers.push({ name: "Mlo", userId: "1003", email: "mlo@gmail.com" });
            requiredPlaceholders++;
        }

        if (!contextSupportingDocs || contextSupportingDocs.length === 0) {
            alert("No documents available.");
            return;
        }

        const documentsArray = await Promise.all(
            contextSupportingDocs.map(async (doc, index) => {
                try {
                    const base64String = await previewDocument(doc);
                    if (!base64String) throw new Error("Failed to convert document to Base64");

                    return {
                        documentBase64: base64String,
                        name: doc.fileName,
                        fileExtension: "pdf",
                        documentId: (index + 1).toString(),
                    };
                } catch (error) {
                    console.error("Error processing file:", doc.fileName, error);
                    return null;
                }
            })
        );

        const filteredDocumentsArray = documentsArray.filter(Boolean);
        if (filteredDocumentsArray.length === 0) {
            alert("Failed to fetch any valid PDF documents.");
            return;
        }

        // Call API to upload the document and get placeholder count
        const res = await postApiCallDynamic({
            path: API.docuSignUploadDoc,
            data: { name, userId, contextSupportingDocs: filteredDocumentsArray, signers },
            refresh: (response) => {
                if (response?.data?.fieldPlacementUrl) {
                    console.log("Response Data: " + JSON.stringify(response, null, 2));

                    const placeholdersCount = response.data.placeholdersCount || 0; // Assume API returns placeholder count

                    // if (placeholdersCount < requiredPlaceholders) {
                    //     alert(`You need at least ${requiredPlaceholders} placeholders, but only ${placeholdersCount} were found. Please edit the document and add the missing placeholders.`);
                    //     return;
                    // }

                    setEnvelopeId(response?.data?.envelopeId)
                    // console.log(" Envelope id in handler " + envelopeId)
                    const popup = window.open(
                        response.data.fieldPlacementUrl,
                        "DocuSignPopup",
                        "width=800,height=600,scrollbars=yes,resizable=yes"
                    );
                    if (!popup) {
                        alert("Popup blocked! Allow popups for this site.");
                    }
                } else {
                    alert("Error creating envelope");
                    console.error("DocuSign API Error:", response);
                }
            },
            header: { "Content-Type": "application/json" }
        });

        if (!res || !res.data) {
            console.error("Invalid response from API", res);
            return;
        }

        console.log("API Response:", res);

        if (res.data.editUrl) {
            const popup = window.open(
                res.data.editUrl,
                "DocuSignPopup",
                "width=800,height=600,scrollbars=yes,resizable=yes"
            );
            if (!popup) {
                alert("Popup blocked! Allow popups for this site.");
            }
        } else {
            alert("Error creating envelope");
            console.error("DocuSign API Error:", res.data);
        }
    };

    return (
        <>
            <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                <Button
                    variant="outlined"
                    onClick={() => handleUploadAndEditMultipleFiles()}
                >
                    Prepare for E-Sign
                </Button>
            </Stack>
        </>
    );
};

export default ESignHandler;
